<template>
  <section class="Join-movement-section mb-10">
    <div class="container p-5">
      <div class="row">
        <div class="col-sm-12">
          <h1 class="section-title">Join the movement & get started today</h1>
          <p class="section-paragraph">
            Unleash the power of AI to make informed product decisions and drive e-commerce success.
          </p>
        </div>
        <div
          v-if="!isScheduleCall"
          class="shopfiy-hero-container d-flex align-items-center justify-content-center pt-5"
        >
          <a
            target="_blank"
            class="d-flex align-items-center gap-2 custom-button filled"
            href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
          >
            <img
              src="@/assets/icons/Shopify_logo_w.svg"
              alt="Shopify"
              title="Shopify"
              loading="lazy"
            />
            Get Shopify App for Free
          </a>
          <a
            target="_blank"
            class="book-demo-btn d-flex align-items-center gap-2 custom-button"
            href="https://calendly.com/vgang/vgang-for-dropshipers?month=2023-08"
          >
            Book a demo
          </a>
        </div>
        <div
          v-else
          class="shopfiy-hero-container d-flex align-items-center justify-content-center pt-5"
        >
          <a target="_blank" :href="url">
            <button
              class="d-flex align-items-center justify-content-center custom-button filled hero-button"
            >
              <img
                src="@/assets/icons/calendar_event.svg"
                style="margin-right: 5px"
                width="20px"
                height="20px"
                alt="shopify logo"
              />
              Schedule a call with us
            </button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    isScheduleCall: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped></style>
