<template>
  <section class="cost-section">
    <div class="container">
      <div class="costs">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-title">How much does it cost?</h2>
            <span class="sub-title">
              VGANG Services are Free for Suppliers
            </span>
            <p class="description text-center hidden-on-mobile">
              VGANG connects sustainable suppliers of planet-friendly, vegan and
              zero-waste products with a global distribution network of online
              retailers and micro-influencers. Grow your brand and increase
              sales without spending on advertising. All VGANG services for
              suppliers are free.<br />
              Join us today to make a difference!
            </p>
            <div class="costs-table">
              <div class="texts">
                <ul>
                  <li class="each-item">
                    <span class="icon icon-green">
                      <span class="material-symbols-outlined">
                        check_circle
                      </span>
                    </span>
                    <span class="text">Instant payment for all orders </span>
                  </li>
                  <li class="each-item">
                    <span class="icon icon-green">
                      <span class="material-symbols-outlined">
                        check_circle
                      </span>
                    </span>
                    <span class="text">Seamless intgration with Shopify </span>
                  </li>
                  <li class="each-item">
                    <span class="icon icon-green">
                      <span class="material-symbols-outlined">
                        check_circle
                      </span>
                    </span>
                    <span class="text">Automated price & inventory change</span>
                  </li>
                  <li class="each-item">
                    <span class="icon icon-green">
                      <span class="material-symbols-outlined">
                        check_circle
                      </span>
                    </span>
                    <span class="text"
                      >Access to thousands of retailers and influencers</span
                    >
                  </li>
                  <li class="each-item">
                    <span class="icon icon-green">
                      <span class="material-symbols-outlined">
                        check_circle
                      </span>
                    </span>
                    <span class="text">Dedicated account manager</span>
                  </li>
                </ul>
              </div>
              <div class="box">
                <span class="box-title">All features included for Free</span>
                <span class="price">$0</span>
                <span class="box-description"
                  >No setup, commission, or hidden fees.
                </span>

                <a
                  target="_blank"
                  class=""
                  href="https://calendly.com/vgang/vgang-for-suppliers"
                >
                  <button
                    class="d-flex align-items-center justify-content-center custom-button filled hero-button"
                  >
                    <img
                      src="@/assets/icons/calendar_event.svg"
                      style="margin-right: 5px"
                      alt="shopify logo"
                    />
                    Schedule a call with us
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cost-section {
  margin-top: 75px;
  margin-bottom: 75px;
  @include md {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  @include sm {
    margin-top: 44px;
    margin-bottom: 44px;
  }
  .section-title {
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #344054;
    @include md {
      font-size: 28px;
    }
  }
  .description {
    color: #344054;
    font-size: 16px;
    font-weight: 600;
    line-height: 34px;
  }
  .sub-title {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #344054;
    display: block;
    margin: 14px auto;
    @include md {
      font-size: 20px;
    }
  }
  .costs-table {
    padding: 44px;
    border-radius: 20px;
    background-color: #edf7ee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 20px;
      background-color: #fff;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      @media (max-width: 768px) {
        margin-bottom: 36px;
      }
      .each-item {
        display: flex;
        align-items: center;
        margin-bottom: 45px;
        @media (max-width: 768px) {
          margin-bottom: 25px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .text {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #344054;
          @media (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
    .icon {
      text-align: center;
      font-size: 18px;
      margin-right: 10px;
    }
    .icon-green {
      color: #008f4a;
    }
    .box {
      padding: 70px;
      border-radius: 20px;
      background-color: #cde9ce;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      @media (max-width: 768px) {
        padding: 40px;
      }
      .box-title {
        font-size: 24px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #344054;
        margin-bottom: 18px;
        @media (max-width: 768px) {
          font-size: 18px;
          text-align: center;
        }
      }
      .box-description {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 18px;
        @media (max-width: 768px) {
          font-size: 14px;
          text-align: center;
        }
      }
      .price {
        font-size: 120px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #344054;
        margin-bottom: 18px;
        @include md {
          font-size: 85px;
          text-align: center;
        }
      }
    }
  }
}
</style>
