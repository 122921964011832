<template>
  <section class="benefit-of-selling-section">
    <div class="container">
      <div class="getting-started">
        <div class="row">
          <div class="col-sm-12">
            <h1 class="section-title">Benefits of selling on VGANG</h1>
            <p class="section-paragraph">
              Sell Planet-Friendly Products and Grow Your Business
            </p>
            <p class="header-description text-center">
              Choose VGANG for instant order payments, zero fees, and access to
              a community of like-minded retailers. With seamless integration
              and worldwide growth potential, VGANG makes selling your
              planet-friendly products fast and easy.
            </p>
          </div>
        </div>
        <div class="cards-container">
          <div class="row">
            <div class="col-xl-4 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/partnership/InstantpPayouts.webp"
                alt=" Earth friendly products"
                title="Earth friendly products"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  <span class="text-green"> Instant</span> payouts
                </h3>
                <p class="description mt-3">
                  No waiting time for order payments.
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/partnership/Free.webp"
                alt=" Sourced from local suppliers"
                title="Sourced from local suppliers"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  Sourced from <span class="text-green"> local suppliers</span>
                </h3>
                <p class="description mt-3">
                  No setup, commission, or hidden fees.
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/partnership/EasyOnlineSelling.webp"
                alt=" Super fast shipping"
                title=" Super fast shippings"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  <span class="text-green"> Easy</span> online selling
                </h3>
                <p class="description mt-3">
                  Seamlessly integrate with Shopify.
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/partnership/LikeMindedRetailers.webp"
                alt="Hand picked products"
                title="Hand picked products"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  <span class="text-green"> Like-minded </span> retailers
                </h3>
                <p class="description mt-3">
                  Connect with thousands of green retailers.
                </p>
              </div>
            </div>

            <div class="col-xl-4 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/partnership/BoostYourProfit.webp"
                alt=" Sustainable profit"
                title="Sustainable profit"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  <span class="text-green"> Boost </span> your profit
                </h3>
                <p class="description mt-3">
                  Access a new market and increase revenue.
                </p>
              </div>
            </div>
            <div class="col-xl-4 col-md-6 card mt-7 px-3">
              <img
                src="@/assets/img/partnership/FightForThePlanet.webp"
                alt="Access to a community"
                title="Access to a community"
                loading="lazy"
              />
              <div class="body">
                <h3 class="card-title mt-7">
                  Fight for the
                  <span class="text-green">Planet </span>
                </h3>
                <p class="description mt-3">
                  Increase access to sustainable products.
                </p>
              </div>
            </div>
          </div>
          <div class="buttons-container mt-10">
            <a
              target="_blank"
              class="d-flex justify-content-center align-items-center gap-2 custom-button filled"
              href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
            >
              <img
                src="@/assets/icons/Shopify_logo_w.svg"
                alt="Shopify"
                title="Shopify"
                loading="lazy"
              />
              Get Shopify App for Free
            </a>
            <a
              style="border-color: #d0d5dd"
              target="_blank"
              class="d-flex justify-content-center mt-5 align-items-center gap-2 custom-button"
              href="https://apps.shopify.com/vgang-dropshipping-retailers?surface_detail=vgang+for+dropshipper&surface_inter_position=1&surface_intra_position=1&surface_type=search"
            >
              Book a Demo
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GettingStarted",
  props: {
    msg: String,
  },
};
</script>
<style lang="scss" scoped>
.card {
  background: unset;
}
</style>
