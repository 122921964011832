<template>
  <div class="partnership shopify-landing">
    <Hero />
    <UserComment
      url="https://driibe.com/"
      author="DRiiBE"
      profile="https://eu.driibe.com/cdn/shop/files/DRiiBE_black_2_PNG_R_2_6e49d821-f958-4c39-8bde-a21c2cf8dd9b_128x.png?v=1656327057"
      description="Great app. It's very easy to install and use. Order payments are available immediately, so there is no waiting time for payouts - this is a great advantage. The orders are automatically synced with Shopify. "
    />
    <BenefitsOfSellingOnVgang />
    <ExpandNetworkBanner />
    <CostSection />
    <UserComment
      url="https://www.adoratherapy.com/"
      :adoratherapyStyle="true"
      class="adoratherapy-comment-component"
      profile="https://adoratherapy.com/cdn/shop/files/Dl_tK9wDlHnpcJ3nvSJjB-PtgZOr5Nc8Kg_x100.png"
      description="We signed up for Vgang because our values align with their
    mission and we always like to try new platforms. We’ve received two orders
    the first week we are up. We re excited to find a great audience. The team
    has been very helpful even telling us before we got the order and making
    sure we did everything right. The order appeared in Shopify, already tagged,
    easy as can be. We adore you VGANG!"
    />
    <QaBaseComponent
      titleFor="Suppliers"
      api="https://blog.vgang.io/wp-json/wp/v2/posts?categories=28&per_page=6&offset=0"
    />
    <JoinTheMovement
      url="https://calendly.com/vgang/vgang-for-suppliers"
      :isScheduleCall="true"
    />
  </div>
</template>

<script>
import UserComment from "@/components/home/UserComment.vue";
import Hero from "@/components/partnership/Hero.vue";
import buttonLink from "@/components/bases/ButtonLink.vue";
import CostSection from "../components/partnership/CostSection.vue";
import BenefitsOfSellingOnVgang from "../components/partnership/BenefitsOfSellingOnVgang.vue";
import ExpandNetworkBanner from "@/components/partnership/ExpandNetworkBanner.vue";
import axios from "axios";
import QaBaseComponent from "@/components/bases/QaBaseComponent.vue";
import JoinTheMovement from "@/components/bases/JoinTheMovement.vue";
const baseURL = "https://api.vgang.io/api/v1/";
export default {
  name: "Shopify",
  components: {
    BenefitsOfSellingOnVgang,
    buttonLink,
    ExpandNetworkBanner,
    Hero,
    CostSection,
    UserComment,
    ExpandNetworkBanner,
    QaBaseComponent,
    JoinTheMovement,
    JoinTheMovement,
  },
  data() {
    return {
      companyName: "",
      websiteURL: "",
      fullName: "",
      email: "",
      cellNumber: "",
      shopifyAccount: false,
      isManufacturer: false,
      hasInventory: false,
      step: 0,
      isDisabled: true,
      errorMessage: "",
      hasError: false,
      posts: [],
      errors: [],
      category: 28,
    };
  },
  mounted() {
    this.getPosts(this.category);
  },
  methods: {
    handleFirstForm() {
      const params = {
        company_name: this.companyName,
        website_url: this.websiteURL,
        full_name: this.fullName,
        email: this.email,
        cell_number: this.cellNumber,
      };
      this.axios
        .post(baseURL + "landing/forms/supplier/first-step", params)
        .then((response) => {
          this.hasError = false;
          this.step = 1;
          this.uID = response.data.id;
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.status == 409) {
            this.hasError = true;
            this.errorMessage =
              "This email registered before, we will contact you as soon as we launch";
          } else if (e.response.status == 400) {
            this.hasError = true;
            this.errorMessage = "Fix the errors before submiting";
          }
        });
    },

    getPosts(category) {
      axios
        .get(
          "https://blog.vgang.io/wp-json/wp/v2/posts?categories=" +
            category +
            "&per_page=6&offset=0"
        )

        .then((response) => {
          console.log(response);
          this.posts = response.data;
        })
        .catch((err) => {
          console.log(err);
          //this.errors.push(err);
        });
    },

    handleSecondForm() {
      this.hasError = false;
      const params = {
        is_shopify: this.shopifyAccount,
        is_manufacture: this.isManufacturer,
        manage_inventory: this.hasInventory,
      };
      this.axios
        .post(
          baseURL + "landing/forms/supplier/second-step/" + this.uID,
          params
        )
        .then((response) => {
          this.step = 2;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    checkButtonActiveStatus() {
      this.hasError = false;
      if (
        this.companyName.length > 0 &&
        this.websiteURL.length > 0 &&
        this.fullName.length > 0 &&
        this.email.length > 0 &&
        this.cellNumber.length > 0
      ) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    handleSuccessModalClose() {
      this.$bvModal.hide("partnership-modal");
    },
  },
  filters: {
    truncate: function (text, length, clamp) {
      return text.length > length ? text.slice(0, length) + clamp : text;
    },
  },
};
</script>

<style lang="scss"></style>
