<template>
  <div class="partnership-hero">
    <div class="container">
      <div class="row">
        <div class="message_container col-sm-12 col-md-6">
          <h1 class="hero-title">
            Unlock <span class="green-text"> New Markets</span><br />
            and Sell Your<br />
            <span class="green-text">Sustainable</span>
            Products with Ease, for
            <span class="green-text">Free</span>!
          </h1>
          <p class="hero-paragraph hidden-on-mobile">
            Expand your reach and connect with environmentally-conscious
            customers worldwide through VGANG's network of dropshippers.
          </p>
          <p class="hero-paragraph hidden-on-web">
            VGANG connects vegan, sustainable suppliers and brands to online
            dropshippers and increases their distribution around Europe.
          </p>
          <a href="https://calendly.com/vgang/vgang-for-suppliers">
            <button
              class="d-flex align-items-center justify-content-center custom-button filled hero-button"
            >
              <img
                src="@/assets/icons/calendar_event.svg"
                style="margin-right: 5px"
                width="20px"
                height="20px"
                alt="shopify logo"
              />
              Schedule a call with us
            </button>
          </a>
        </div>
        <div class="img_container col-sm-12 col-md-6 hidden-on-mobile">
          <div class="image-container">
            <img
              src="@/assets/img/women.webp"
              alt="a happy women works with laptop"
              class="shopify-hero-image"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
