<template>
  <div class="user-comment-component">
    <div class="container">
      <p class="description">
        {{ description }}
      </p>
      <div
        :class="{ 'adoratherapy-comment-component': adoratherapyStyle }"
        class="author-container d-flex gap-4"
      >
      

        <div class="author d-flex flex-column justify-content-between">
          <img
            src="@/assets/icons/dark-starts.svg"
            alt="starts"
            title="starts"
          />

          <a target="_blank" :href="url" class="author-name mb-0">
            {{ author }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
    },
    profile: {
      type: String,
    },
    author: {
      type: String,
    },
    url: {
      type: String,
    },
    adoratherapyStyle: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped></style>
