<template>
  <div class="expand-network-banner">
    <div class="container">
      <div class="banner-box text-center gap-4">
        <h3 class="title">
          Expand Your Distribution Network and Earn Commissions
        </h3>
        <p class="text-green mb-0">
          By referring retailers to VGANG , you can earn commissions and benefit
          from automated order processing and product syncing.
        </p>
        <p class="description mb-0">
          If you already know retailers who love selling your vegan and
          planet-friendly products online, you can refer them to VGANG
          dropshipper app to enjoy seamless product and order integration.
        </p>
        <a
          target="_blank"
          href="https://calendly.com/vgang/vgang-for-suppliers"
        >
          <button
            class="d-flex align-items-center justify-content-center custom-button filled hero-button"
          >
            <img
              src="@/assets/icons/calendar_event.svg"
              style="margin-right: 5px"
              alt="shopify logo"
            />
            Schedule a call with us
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.expand-network-banner {
  background: #edf7ee;
  padding: 44px;
  margin-bottom: 55px;
  @include md {
    margin-bottom: 44px;
    padding: 34px 21px;
  }
  .banner-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include md {
      gap: 8px !important;
    }
    .title {
      font-size: 28px !important;
      font-weight: 700;
      line-height: 32px;
      color: #344054;
      margin-bottom: 21px;
      @include md {
        font-size: 21px !important;
        margin-bottom: 14px;
      }
    }
    .text-green {
      color: #008f4a;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 21px !important;
      @include md {
        margin-bottom: 8px !important;
      }
    }
    .description {
      color: #344054;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 24px !important;
      justify-content: center;
    }
  }
}
</style>
