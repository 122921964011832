<template>
  <section class="meet-supplier-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h1 class="section-title">Other {{ titleFor }} Asked</h1>
          <p class="section-paragraph">
            Answers to Your Questions: VGANG's FAQ for {{ titleFor }}
          </p>
        </div>
        <div class="slides row">
          <div
            v-for="(post, index) in posts"
            :key="post.id"
            class="slide col-md-6 col-xs-12 mt-6"
          >
            <h4 class="title">{{ post.title.rendered }}</h4>
            <div
              class="body text-justify"
              :inner-html.prop="post.excerpt.rendered | truncate(473, '...')"
            ></div>
            <a
              class="link"
              :href="post.link"
              target="_blank"
              :aria-label="`you can read more about our post it can be usefull for you ${index}`"
              >More</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  props: ["api", "titleFor"],

  data() {
    return {
      posts: [],
      errors: [],
    };
  },
  mounted() {
    this.getPosts();
  },
  filters: {
    truncate: function (text, length, clamp) {
      return text.length > length ? text.slice(0, length) + clamp : text;
    },
  },
  methods: {
    getPosts() {
      axios
        .get(this.api)
        .then((response) => {
          this.posts = response.data;

          this.posts.forEach((post) => {
            let url = post.jetpack_featured_media_url;

            if (url.includes("~vgangio")) {
              url = url.slice(36);
              url = "http://blog.vgang.io" + url;

              post.jetpack_featured_media_url = url;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.each {
  background: unset !important;
}
</style>
